#banner {
  width: 100%;
  margin-top: -193px;
  color: white;
  background: linear-gradient(180deg, rgba(29, 29, 27, 0) 87.5%, #1d1d1b 100%),
    linear-gradient(0deg, rgba(29, 29, 27, 0.6), rgba(29, 29, 27, 0.6)),
    url("../../assets/pics/hero.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 600px;
  background-size: cover;
}

#banner-text {
  text-align: center;
  height: 600px;
  padding-top: 220px;
}

#banner-text h1 {
  height: 80px;
  margin: 0;
  font-size: 80px;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}

#banner-text h2 {
  font-size: 72px;
  font-weight: 900;
  text-transform: uppercase;
}

#banner-text p {
  font-size: 16px;
  max-width: 572px;
}

#main-workouts {
  padding-top: 72px;
  padding-bottom: 52px;
}

#main-workouts h2 {
  margin-bottom: 32px;
}

#more-workouts {
  margin-top: 32px;
}

#trainers {
  padding-top: 72px;
  padding-bottom: 100px;
  overflow: hidden;
}

#trainers h2 {
  margin-bottom: 48px;
}

#main-galery {
  background-color: var(--gray-bg);
  padding: 32px 70px 100px;
}

#main-galery h2 {
  margin-bottom: 24px;
}

#main-galery img {
  cursor: pointer;
}

.btn-nav-link {
  width: fit-content;
}

@media screen and (max-width: 650px) {
  #banner-text h1 {
    height: 60px;
    font-size: 60px;
  }

  #banner-text h2 {
    font-size: 42px;
  }
}

@media screen and (max-width: 480px) {
  #banner-text h1 {
    height: 40px;
    font-size: 40px;
  }

  #banner-text h2 {
    font-size: 32px;
  }
}
