footer {
  padding-top: 80px;
  padding-bottom: 144px;
  background-color: black;
}

footer h6 {
  margin-bottom: 20px;
}

footer p {
  margin-bottom: 0;
  color: #fff;
}

footer .row .footer-wrap {
  width: fit-content;
  margin: 0 auto;
}

#footer-company {
  margin-bottom: 50px;
}

#footer-logo {
  width: 70px;
  max-height: 80px;
  margin-right: 40px;
}

#footer-social-wrap div {
  display: flex;
  flex-direction: column;
}

.footer-social {
  margin-bottom: 20px;
  max-width: 18px;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  footer .nav-font {
    padding-right: 0 !important;
  }
}
