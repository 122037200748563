#coach-sub-title {
  color: var(--yellow);
  text-align: center;
  margin-bottom: 14px;
  font-weight: 700;
}

#coach-main {
  margin-top: 65px;
  margin-bottom: 62px;
  padding: 0 10%;
}

#coach-img-wrap {
  width: 200px;
  height: 200px;
  flex-shrink: 0;
}

#coach-img-wrap {
  width: 200px;
  height: 200px;
  margin-right: 24px;
  margin-bottom: 24px;
}

#coach-img-wrap-center {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid transparent;
}
#coach-img {
  border-radius: 4px;
  border: 1px solid transparent;
}

#coach-desc {
  color: var(--gray-font);
}

#coach-desc-title {
  color: #fff;
  font-weight: 700;
}

#coach-desc-social img {
  cursor: pointer;
  margin-right: 16px;
}

#coach-workouts {
  margin-top: 100px;
  margin-bottom: 140px;
}

#coach-workouts {
  margin-top: 100px;
  margin-bottom: 100px;
}

#coach-workouts h4 {
  margin-bottom: 60px;
}

#coach-singup {
  margin-bottom: 120px;
}

@media screen and (max-width: 1200px) {
  #coach-img-wrap {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}
