.top-nav {
  top: 0;
  right: 0;
}

.navbar-brand {
  z-index: 100;
}

.wrap {
  z-index: 10;
  background-color: transparent;
}

.nav-font {
  font-size: 1rem !important;
  font-family: "Raleway-bold";
  color: #fff !important;
  transition: color var(--transition-basic) !important;
  padding-left: 0 !important;
  padding-right: 44px !important;
}

.nav-font:hover {
  color: var(--gray-nav-hover) !important;
}

.nav-link.active {
  color: var(--yellow) !important;
}

.offcanvas {
  color: #fff !important;
  background-color: var(--gray-bg) !important;
  width: 275px !important;
}

.offcanvas img.header-social {
  padding-right: 10px;
}

#offc-menu {
  position: absolute;
  top: 0;
  right: 0;
}

#navbar-inner-container {
  position: relative;
}
