.brk-btn {
  outline: none !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 19px !important;
  padding: 10px 16px !important;
  border-radius: 4px !important;
}

.btn-red {
  color: white !important;
  background-color: var(--red) !important;
  border-color: var(--red) !important;
}
.btn-red-outline {
  color: var(--red) !important;
  background-color: transparent !important;
}

.btn-red:hover,
.btn-red:active,
.btn-red:focus {
  color: var(--black) !important;
  background-color: var(--red-hover) !important;
  border-color: var(--red-hover) !important;
  outline-color: var(--red-hover) !important;
}

.btn-red-outline:hover,
.btn-red-outline:active,
.btn-red-outline:focus {
  color: var(--red-hover) !important;
  background-color: transparent !important;
  border-color: var(--red-hover) !important;
  outline-color: var(--red-hover) !important;
}

.btn-yellow {
  color: var(--black) !important;
  background-color: var(--yellow) !important;
  border-color: var(--yellow) !important;
}
.btn-yellow-outline {
  color: var(--yellow) !important;
  background-color: transparent !important;
}

.btn-yellow:hover,
.btn-yellow:active,
.btn-yellow:focus {
  color: var(--black) !important;
  background-color: var(--yellow-hover) !important;
  border-color: var(--yellow-hover) !important;
  outline-color: var(--yellow-hover) !important;
}

.btn-yellow-outline:hover,
.btn-yellow-outline:active,
.btn-yellow-outline:focus {
  color: var(--yellow-hover) !important;
  background-color: transparent !important;
  border-color: var(--yellow-hover) !important;
  outline-color: var(--yellow-hover) !important;
}
