#time_sheet {
  background-image: url("../../assets/pics/timetable_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 32px;
}

#time_sheet h2 {
  margin-bottom: 24px;
}

#time_sheet img {
  max-width: 820px;
  max-height: 360px;
}

#time_sheet img:hover {
  cursor: pointer;
}

.modal-90w {
  width: 90% !important;
  max-width: none !important;
}
