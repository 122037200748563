.form-label {
  color: white;
}

.center-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
