.brk-card {
  background-color: var(--gray-bg) !important;
  color: white;
  transition: background-color var(--transition-basic);
  transition: border-color var(--transition-basic);
  width: 310px;
}
.brk-card:hover {
  background-color: var(--gray-bg-light) !important;
  color: white;
  border-color: var(--yellow);
}

.brk-card h5 {
  color: var(--gray-font-dark);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  margin-bottom: 7px;
}

.brk-card .card-body {
  padding: 24px 20px;
}

.brk-card .card-title {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 900;
  line-height: 23px;
  letter-spacing: 0em;
  margin-bottom: 16px;
}

.brk-card .card-text {
  color: var(--gray-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.card-font:hover,
.card-font:focus {
  color: var(--yellow-hover);
}
