.contact-title {
  margin-bottom: 15px;
}

.contact-lines {
  color: var(--yellow);
  max-width: 638px;
  margin: 0 auto;
}
.contact-lines .col {
  padding: 0 !important;
}
.contact-lines .col:nth-child(2) {
  text-align: center;
}
.contact-lines .col:last-child {
  text-align: right;
}

.contact-map {
  height: 360px;
  max-width: 638px;
}

@media screen and (max-width: 992px) {
  .contact-lines {
    text-align: center;
  }
}
