.prices-wrap {
  max-width: 960px !important;
  color: white;
}

.prices-title {
  margin-bottom: 64px;
}

.prices-sub-title {
  margin-top: 72px;
}

.prices-desc {
  padding-right: 10px;
  padding-left: 65px;
  font-weight: bold;
  background-color: var(--gray-bg-dark);
  z-index: 2;
}

.prices-desc h4 {
  text-align: left;
}

.big-num {
  width: 55px;
  height: 40px;
  line-height: 40px;
  font-size: 40px;
  font-weight: bold;
  position: absolute;
  text-align: end;
  background-color: var(--gray-bg-dark);
  z-index: 3;
}

.red {
  color: var(--red);
}

.yellow {
  color: var(--yellow);
}

.price-title {
  text-align: center;
  margin-bottom: 35px;
  color: var(--gray-font);
  font-weight: bold;
}

.price-line {
  margin-bottom: 10px;
}

.prices-separator {
  margin-top: 15px;
  height: 1px;
  border-bottom: 2px dashed #ffffff40;
  width: 920px !important;
  position: absolute;
  z-index: 1;
}

.prices-price {
  padding-left: 10px;
  background-color: var(--gray-bg-dark);
  z-index: 2;
}

.personal-train-prices {
  width: 280px;
}

.personal-train-title {
  margin-top: 5px;
  margin-bottom: 3px;
  margin-right: 20px;
  text-align: right;
  width: 125px;
}

.prices-triangle {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid var(--red);
  margin-top: -8px;
  margin-left: 20px;
}

.prices-triangle-tiny {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid var(--gray-bg-dark);
  margin-top: -10px;
  margin-left: -16px;
}

.t-yellow {
  border-left-color: var(--yellow);
}

.t-white {
  border-left-color: white;
}

@media screen and (max-width: 768px) {
  .price-line {
    margin-bottom: 50px;
  }
  .prices-separator {
    display: none;
  }
  .price-line {
    padding-bottom: 10px;
    border-bottom: 2px dashed #ffffff40;
  }
}
