.trainer-wrap {
  width: 400px;
  height: 120px;
  color: white;
  display: flex;
}

.trainer-wrap .img-wrap {
  width: 100px;
  height: 100px;
}

.trainer-wrap .img-center {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid transparent;
}
.img-wrap img {
  border-radius: 4px;
  border: 1px solid transparent;
}

.trainer-wrap h5 {
  text-align: left;
  margin-bottom: 7px;
}

.trainer-wrap p {
  color: var(--gray-font);
  line-height: 18px;
  margin-bottom: 7px;
}

#short-desc {
  margin-left: 20px;
}

@media only screen and (max-width: 450px) {
  .trainer-wrap {
    width: 300px;
  }
}

@media only screen and (max-width: 300px) {
  .trainer-wrap {
    width: 250px;
  }
}
