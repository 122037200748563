#workout-sub-title {
  color: var(--yellow);
  text-align: center;
  margin-bottom: 14px;
  font-weight: 700;
}
#workout-img-wrap {
  width: 417px;
  height: 272px;
  margin-right: 24px;
  margin-bottom: 24px;
  flex-shrink: 0;
}

#workout-img {
  width: 100%;
  border-radius: 4px;
}

#workout-desc {
  color: var(--gray-font);
}

#workout-main {
  margin-top: 65px;
  margin-bottom: 30px;
  padding: 0 10%;
}

#workout-trainers {
  margin-top: 44px;
  padding-top: 32px;
  padding-bottom: 32px;
  overflow: hidden;
  background-color: var(--gray-bg);
}

#workout-trainers h4 {
  margin-bottom: 64px;
}

#more-workouts {
  margin-top: 100px;
  margin-bottom: 140px;
}

#more-workouts {
  margin-top: 100px;
  margin-bottom: 120px;
}

#more-workouts h4 {
  margin-bottom: 40px;
}

@media screen and (max-width: 1200px) {
  #workout-img-wrap {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media screen and (max-width: 576px) {
  #workout-img-wrap {
    width: 100%;
    height: auto;
  }
}
