.singup-wrap {
  background: #2b2c2b;
  border: 2px solid var(--red);
  border-radius: 4px;
  padding: 16px 24px;
  color: white;
  max-width: 860px;
}
.singup-wrap h4 {
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  font-family: "Raleway-bold";
  text-transform: uppercase;
  margin: 0;
  text-align: left;
}
.singup-wrap p {
  color: var(--gray-font);
  font-size: 16px;
  line-height: 19px;
  margin: 0;
}
.singup-btn-wrap {
  padding-top: 3px;
}
