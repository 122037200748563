@font-face {
  font-family: "Raleway-bold";
  src: local("Raleway-bold"),
    url("./assets/fonts/Raleway-VariableFont_wght_900.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: "Orbitron-bold";
  src: local("Orbitron-bold"),
    url("./assets/fonts/Orbitron-VariableFont_wght_900.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url("./assets/fonts/Raleway-VariableFont_wght.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Orbitron";
  src: local("Orbitron"),
    url("./assets/fonts/Orbitron-VariableFont_wght.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

* {
  font-family: "Raleway";
}
:root {
  --red: #e20613;
  --red-hover: #ae050f;
  --yellow: #ffcd00;
  --yellow-hover: #d6ac00;
  --black: #1d1d1b;
  --gray-font: #bababa;
  --gray-nav-hover: #999999;
  --gray-font-dark: #737373;
  --gray-bg: #2b2c2b;
  --gray-bg-light: #444644;
  --gray-bg-dark: #1d1d1b;
  --transition-basic: 0.3s ease-in;
}
.raleway-bold {
  font-family: "Raleway-bold";
}
.orbitron-bold {
  font-family: "Orbitron-bold";
}
body {
  background-color: var(--gray-bg-dark);
  min-height: 100vh;
  margin: 0;

  display: grid;
  grid-template-rows: auto 1fr auto;
}

header {
  min-height: 160px;
}

footer {
  min-height: 350px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway-bold";
  text-transform: uppercase;
  color: white;
  font-weight: 900;
  text-align: center;
  margin: 0;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6,
p {
  font-size: 16px;
}

a {
  width: auto;
}

.more-info {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--yellow);
  transition: color var(--transition-basic);
}

.more-info:hover {
  color: var(--yellow-hover);
}

.social-icon {
  transition: opacity var(--transition-basic);
}

.social-icon:hover {
  opacity: 0.5;
}

.common-content-wrap {
  padding-top: 0;
  margin-bottom: 87px;
}

.common-content-wrap > p {
  max-width: 600px;
  margin: 24px auto 83px;
  color: var(--gray-font);
}
